"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const router_1 = require("@reach/router");
const styles_1 = require("@material-ui/core/styles");
const List_1 = require("@material-ui/core/List");
const ListItem_1 = require("@material-ui/core/ListItem");
const ListItemText_1 = require("@material-ui/core/ListItemText");
const ExpandMore_1 = require("@material-ui/icons/ExpandMore");
const useStyles = styles_1.makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
    icon: {
        fontSize: 14,
        margin: 5,
        color: "#FFAA29"
    },
    link: {
        "&:hover": {
            fontWeight: "bold",
        }
    }
}));
function DocsMenu(props) {
    const classes = useStyles();
    return (React.createElement(List_1.default, null,
        React.createElement(ListItem_1.default, null,
            React.createElement(ExpandMore_1.default, { className: classes.icon }),
            React.createElement(ListItemText_1.default, null, "GraphQL")),
        React.createElement(List_1.default, { disablePadding: true, className: classes.nested },
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/primer" }, "GraphQL Primer")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/walkthrough" }, "Sample Walkthrough")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/auth" }, "Authentication")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/explorer" }, "GraphQL Explorer")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/playground" }, "GraphQL Playground"))),
        React.createElement(ListItem_1.default, null,
            React.createElement(ExpandMore_1.default, { className: classes.icon }),
            React.createElement(ListItemText_1.default, null, "API Documentation")),
        React.createElement(List_1.default, { disablePadding: true, className: classes.nested },
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/Query" }, "Queries")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/Mutation" }, "Mutations")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/Object" }, "Objects")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/InputObject" }, "Input Objects")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/Scalar" }, "Scalars")),
            React.createElement(ListItem_1.default, null,
                React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/Enum" }, "Enums")))));
}
exports.default = DocsMenu;
