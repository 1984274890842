"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const styles_1 = require("@material-ui/core/styles");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const constants_1 = require("../../constants");
const Sidebar_1 = require("../Sidebar");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {
        margin: "0 auto",
        marginTop: 55,
        marginBottom: 100,
        maxWidth: 1050,
        [theme.breakpoints.up("md")]: {
            width: 1050
        },
    },
    mainArea: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: 37,
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    }
}));
function Api({ children }) {
    const classes = useStyles();
    const isMobile = useMediaQuery_1.default(`(max-width:${constants_1.MOBILE_BREAKPOINT}px)`);
    return (React.createElement("div", { className: classes.root },
        React.createElement(Grid_1.default, { container: true },
            isMobile || React.createElement(Sidebar_1.default, null),
            React.createElement(Grid_1.default, { item: true, sm: 9, xs: 12, className: classes.mainArea }, children))));
}
exports.default = Api;
