"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeToken = token => ({
    token,
    type: 'CHANGE_TOKEN',
});
exports.setEndpoint = endpoint => ({
    endpoint,
    type: 'SET_ENDPOINT',
});
