"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Table_1 = require("@material-ui/core/Table");
const TableBody_1 = require("@material-ui/core/TableBody");
const TableCell_1 = require("@material-ui/core/TableCell");
const TableRow_1 = require("@material-ui/core/TableRow");
const Typography_1 = require("@material-ui/core/Typography");
const useStyles = styles_1.makeStyles(theme => ({
    name: {
        fontSize: 14,
        fontWeight: "bold"
    }
}));
function Enums({ entities }) {
    const classes = useStyles();
    return (React.createElement(Table_1.default, null,
        React.createElement(TableBody_1.default, null, entities.map(entity => (React.createElement(TableRow_1.default, { key: entity.name },
            React.createElement(TableCell_1.default, null,
                React.createElement(Typography_1.default, { className: classes.name }, entity.name)),
            React.createElement(TableCell_1.default, null,
                React.createElement("span", { className: classes.name }, "One of"),
                ":",
                " ",
                entity
                    .getValues()
                    .map(v => v.name)
                    .join(" | "))))))));
}
exports.default = Enums;
