"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Typography_1 = require("@material-ui/core/Typography");
const Divider_1 = require("@material-ui/core/Divider");
const Title_1 = require("../Title");
const Link_1 = require("../Link");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {
        margin: "0 auto"
    },
    divider: {
        marginTop: 60,
        marginBottom: 47,
        borderBottom: "1px solid #E1E1E1"
    }
}));
function GraphQLPrimer({ children }) {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(Title_1.default, null, "GraphQL Primer"),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Getting Started"),
        React.createElement(Typography_1.default, { paragraph: true }, "SKUx enables retail outlets to track offers and offers that they have redeemed. Using the SKUx API, retailers can check balances, view transactions and redemptions, and search offers."),
        React.createElement(Typography_1.default, { paragraph: true },
            "To get started, you need an API authentication key. Enter your email address ",
            React.createElement(Link_1.default, { to: "/api/auth" }, "here"),
            " to receive the key."),
        React.createElement(Divider_1.default, { className: classes.divider }),
        React.createElement(Title_1.default, null, "GraphQL"),
        React.createElement(Typography_1.default, { paragraph: true }, "SKUx has adopted GraphQL for our API. If this is your first time using GraphQL, read through the following guides. It will help introduce you to GraphQL concepts, and help you begin experimenting with GraphQL."),
        React.createElement(Title_1.default, { variant: "subtitle" }, "What is GraphQL"),
        React.createElement(Typography_1.default, { paragraph: true }, "GraphQL is a query language and a runtime system. Clients form requests (called queries and mutations) by using the GraphQL query language, and the GraphQL server executes the request and returns the data in a response. A GraphQL API makes all data available at a single endpoint. A client specifies exactly the data that it wants, and the server responds with only that data."),
        React.createElement(Typography_1.default, { paragraph: true }, "Some things to know about GraphQL"),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Everything is typed and part of a schema"),
        React.createElement(Typography_1.default, { paragraph: true }, "Everything that's available through a GraphQL API is included in its schema. You can use the schema to build queries that return only the data that you need. This solves the problem of over-fetching data. It also means that we know which fields each app is using, making it easier for us to evolve our APIs over time."),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Everything is available from a single endpoint"),
        React.createElement(Typography_1.default, { paragraph: true }, "All GraphQL requests are sent to the /admin/api/2019-07/graphql.json endpoint, which means that you can often retrieve all the data that you need in a single request. Using GraphQL features such as connections, variables, and aliases, you can make your queries incredibly efficient and retrieve data about many resource types in a single request."),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Documentation is a first-class citizen"),
        React.createElement(Typography_1.default, { paragraph: true }, "The documentation for a GraphQL API lives side-by-side with the code that constitutes it. Combined with the typed schema, this means that we can generate accurate and up-to-date documentation whenever something changes. Using GraphQL's introspection feature, you can query the schema itself to explore its contents and documentation."),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Deprecation is a first-class citizen"),
        React.createElement(Typography_1.default, { paragraph: true }, "We can easily mark a part of our schema as deprecated, and this will also be reflected in documentation. GraphQL tooling, such as client libraries, is also expected to communicate to the developer that they're using a deprecated tool."),
        React.createElement(Divider_1.default, { className: classes.divider }),
        React.createElement(Title_1.default, null, "Sending Your First Request"),
        React.createElement(Typography_1.default, { paragraph: true }, "To start, you need to set up a SKUx Redeemer account, and receive the API Authentication key. To get started:"),
        React.createElement(Typography_1.default, null,
            "1) ",
            React.createElement(Link_1.default, { to: "/api/auth" }, "Create your account"),
            " if you don't have one"),
        React.createElement(Typography_1.default, null, "2) Receive an Authentication key to your email address"),
        React.createElement(Typography_1.default, null, "3) Enter the Authentication key on the GraphQL page"),
        React.createElement(Divider_1.default, { className: classes.divider }),
        React.createElement(Title_1.default, null, "GraphQL Queries"),
        React.createElement(Typography_1.default, { paragraph: true }, "A GraphQL query retrieves data from a server. All GraphQL queries are sent to a single endpoint and use the POST http method."),
        React.createElement(Typography_1.default, { paragraph: true }, "A GraphQL API models data as nodes connected by edges. A node is an object that has a global ID, such as an Order object or Product object. You can fetch data about an individual node, or you can follow the edges to fetch data about a collection of related nodes. At each node, you specify the fields that you want to retrieve."),
        React.createElement(Divider_1.default, { className: classes.divider }),
        React.createElement(Title_1.default, null, "GraphQL Mutations"),
        React.createElement(Typography_1.default, { paragraph: true }, "GraphQL mutations create and modify objects. Mutation requests are sent to the same endpoint as query requests."),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Mutation structure"),
        React.createElement(Typography_1.default, { paragraph: true },
            "Mutations have the following structure:",
            React.createElement("ul", null,
                React.createElement("li", null, "The mutation keyword"),
                React.createElement("li", null, "The name of a mutation"),
                React.createElement("li", null, "The data to use in the mutation passed as an argument"),
                React.createElement("li", null, "A selection of return fields that should be included in the response"))),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Input objects"),
        React.createElement(Typography_1.default, { paragraph: true }, "Mutations require input data, such as the data to create a new object, or the ID of an object to delete. For mutations that might require a substantial data object, the schema provides a dedicated input object type."),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Return fields"),
        React.createElement(Typography_1.default, { paragraph: true }, "Each mutation provides a set of fields that can be returned in the response. For example, one of the return fields available for the customerCreate mutation is the Customer object that was created by a successful mutation. As with a GraphQL query, you can select the fields on the new object that you want to include in the response."),
        React.createElement(Typography_1.default, { paragraph: true }, "Each mutation also returns the userErrors field. The userErrors field returns information about errors when a mutation fails. You should include the userErrors field with each mutation to make it easier to troubleshoot failed mutations.")));
}
exports.default = GraphQLPrimer;
