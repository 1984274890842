"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Grid_1 = require("@material-ui/core/Grid");
const DocsMenu_1 = require("./DocsMenu");
const useStyles = styles_1.makeStyles(theme => ({
    sidebar: {
        textDecoration: "none"
    },
    bordered: {
        borderRight: "1px solid #DDDDDD"
    },
}));
function Sidebar(props) {
    const classes = useStyles();
    return (React.createElement(Grid_1.default, { item: true, xs: 3, className: classes.sidebar },
        React.createElement("div", { className: classes.bordered },
            React.createElement(DocsMenu_1.default, null))));
}
exports.default = Sidebar;
