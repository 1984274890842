"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const router_1 = require("@reach/router");
const styles_1 = require("@material-ui/core/styles");
const styles_2 = require("@material-ui/core/styles");
const useMediaQuery_1 = require("@material-ui/core/useMediaQuery");
const CssBaseline_1 = require("@material-ui/core/CssBaseline");
const SwipeableDrawer_1 = require("@material-ui/core/SwipeableDrawer");
const List_1 = require("@material-ui/core/List");
const ListItem_1 = require("@material-ui/core/ListItem");
const ListItemText_1 = require("@material-ui/core/ListItemText");
const Typography_1 = require("@material-ui/core/Typography");
const Divider_1 = require("@material-ui/core/Divider");
const Grid_1 = require("@material-ui/core/Grid");
const TextField_1 = require("@material-ui/core/TextField");
const Button_1 = require("@material-ui/core/Button");
const Toolbar_1 = require("@material-ui/core/Toolbar");
const IconButton_1 = require("@material-ui/core/IconButton");
const Menu_1 = require("@material-ui/icons/Menu");
const Close_1 = require("@material-ui/icons/Close");
const Home_1 = require("../Home");
const Api_1 = require("../Api");
const Story_1 = require("../Story");
const GraphQLPrimer_1 = require("../GraphQLPrimer");
const Walkthrough_1 = require("../Walkthrough");
const GetToken_1 = require("../GetToken");
const GraphQLPlayground_1 = require("../GraphQLPlayground");
const GraphQLExplorer_1 = require("../GraphQLExplorer");
const APIReference_1 = require("../APIReference");
const DocsMenu_1 = require("../Sidebar/DocsMenu");
const { REST_SERVICE_ENDPOINT } = process.env;
const constants_1 = require("../../constants");
const THEME = styles_2.createMuiTheme({
    typography: {
        fontFamily: '"GT America", "Helvetica", "Arial", sans-serif',
        button: {
            textTransform: "none"
        }
    },
    overrides: {
        MuiTableCell: {
            root: {
                border: "1px solid #E1E1E1"
            }
        },
        MuiTypography: {
            root: { color: "#525252" }
        },
        MuiButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#FFAA29",
                    color: "#FFFFFF"
                }
            }
        }
    }
});
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    "@global": {
        body: {
            backgroundColor: "#fff"
        }
    },
    root: {
        margin: "0 auto"
    },
    paper: {},
    footer: {
        [theme.breakpoints.up("sm")]: {
            height: 260
        },
        width: "100%",
        backgroundColor: "#EEEEEE"
    },
    footerContent: {
        marginTop: 60,
        textAlign: "center",
        margin: "0 auto",
        [theme.breakpoints.up("sm")]: {
            width: 786
        },
        fontSize: 20,
        lineHeight: "30px"
    },
    footerTitle: {
        fontSize: 20,
        color: "#262626",
        lineHeight: "30px",
        height: 50
    },
    footerCopyright: {
        width: "100%",
        marginTop: 57,
        [theme.breakpoints.up("sm")]: {
            paddingRight: 180,
            textAlign: "right"
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "center"
        },
        color: "#B0B3B9",
        fontSize: 12
    },
    subscribeForm: {
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column"
        }
    },
    textField: {
        color: "#C0C0C0",
        height: 40,
        width: 240,
        border: "1px solid #DDDDDD",
        backgroundColor: "#FFFFFF",
        marginRight: 14,
        paddingLeft: 15,
        fontSize: 16,
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginBottom: theme.spacing(2)
        }
    },
    input: {
        padding: "4px 0 7px"
    },
    button: {
        color: "#FFAA29",
        border: "1px solid #FFAA29",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: 16,
        lineHeight: "24px",
        height: 40
    },
    toolbar: {
        backgroundColor: "#387FFD"
    },
    toolbarInner: {
        [theme.breakpoints.up("md")]: {
            minWidth: 1050,
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between"
        }
    },
    toolbarLinks: {
        paddingTop: 4
    },
    toolbarLink: {
        margin: 20,
        textDecoration: "none",
        color: "#fff",
        "&:hover": {
            opacity: 0.7
        }
    },
    hamburger: {
        marginTop: -5,
        color: "#fff"
    },
    close: {
        color: "#fff",
        position: "absolute",
        right: 0
    },
    drawerList: {
        width: 300
    },
    drawerLogo: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#387FFD",
        minHeight: 56,
        paddingLeft: 16
    },
    playground: {}
}));
const subscribeNewsletter = (name, email) => __awaiter(this, void 0, void 0, function* () {
    return yield fetch(`${REST_SERVICE_ENDPOINT}/subscribe`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ name, email })
    }).then(resp => {
        if (!resp.ok) {
            throw resp;
        }
        return resp.json();
    });
});
function Main(props) {
    const { schema, schemaRaw } = props;
    const classes = useStyles();
    const isMobile = useMediaQuery_1.default(`(max-width:${constants_1.MOBILE_BREAKPOINT}px)`);
    const [subscribed, setSubscribed] = React.useState(false);
    const [name, setName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    React.useEffect(() => {
        return router_1.globalHistory.listen(({ action }) => {
            if (action === "PUSH")
                setOpenDrawer(false);
        });
    }, []);
    const handleSetName = e => setName(e.target.value);
    const handleSetEmail = e => setEmail(e.target.value);
    const handleToggleDrawer = e => setOpenDrawer(!openDrawer);
    const handleSubscribe = () => {
        if (name && email) {
            subscribeNewsletter(name, email)
                .then(response => setSubscribed(true))
                .catch(e => {
                e.json().then(resp => setError(resp.message));
            });
        }
        else {
            setError("Please provide name and email");
        }
    };
    return (React.createElement("div", null,
        React.createElement(styles_2.MuiThemeProvider, { theme: THEME },
            React.createElement(CssBaseline_1.default, null),
            React.createElement(Toolbar_1.default, { className: classes.toolbar },
                isMobile && (React.createElement(IconButton_1.default, { edge: "start", className: classes.hamburger, "aria-label": "menu", onClick: handleToggleDrawer },
                    React.createElement(Menu_1.default, null))),
                openDrawer ? (React.createElement(SwipeableDrawer_1.default, { open: true, onClose: handleToggleDrawer },
                    React.createElement("div", { className: classes.drawerLogo },
                        React.createElement(router_1.Link, { to: "/" },
                            React.createElement("img", { src: "/images/SKUx-logo-white-developers.svg" })),
                        React.createElement(IconButton_1.default, { className: classes.close, "aria-label": "close", onClick: handleToggleDrawer },
                            React.createElement(Close_1.default, null))),
                    React.createElement(Divider_1.default, null),
                    React.createElement(List_1.default, { className: classes.drawerList },
                        React.createElement(ListItem_1.default, null,
                            React.createElement(router_1.Link, { to: "/story", onClick: handleToggleDrawer },
                                React.createElement(ListItemText_1.default, null, "The SKUx Story")))),
                    React.createElement(Divider_1.default, null),
                    React.createElement(DocsMenu_1.default, null))) : (React.createElement("div", { className: classes.toolbarInner },
                    React.createElement(router_1.Link, { to: "/" },
                        React.createElement("img", { src: "/images/SKUx-logo-white-developers.svg" })),
                    isMobile || (React.createElement("div", { className: classes.toolbarLinks },
                        React.createElement(router_1.Link, { className: classes.toolbarLink, to: "/story" }, "The SKUx Story"),
                        React.createElement(router_1.Link, { className: classes.toolbarLink, to: "/api/auth" }, "GraphQL"),
                        React.createElement(router_1.Link, { className: classes.toolbarLink, to: "/api/reference/Query" }, "API Documentation")))))),
            React.createElement(Grid_1.default, { container: true, id: "container" },
                React.createElement(Grid_1.default, { item: true, className: classes.root },
                    React.createElement("div", { className: classes.paper },
                        React.createElement(router_1.Router, { primary: false },
                            React.createElement(Home_1.default, { path: "/" }),
                            React.createElement(Story_1.default, { path: "/story" }),
                            React.createElement(Api_1.default, { path: "/api" },
                                React.createElement(GraphQLPrimer_1.default, { path: "primer" }),
                                React.createElement(Walkthrough_1.default, { path: "walkthrough" }),
                                React.createElement(GetToken_1.default, { path: "auth" }),
                                React.createElement(GraphQLExplorer_1.default, { path: "explorer" }),
                                React.createElement(GraphQLPlayground_1.default, { classes: classes.playground, path: "playground" }),
                                React.createElement(APIReference_1.default, { path: "reference/:entityType/:entityName", schemaRaw: schemaRaw, schema: schema }),
                                React.createElement(APIReference_1.default, { path: "reference/:entityType", schema: schema, schemaRaw: schemaRaw }),
                                React.createElement(APIReference_1.default, { path: "reference", schema: schema, schemaRaw: schemaRaw }))))),
                React.createElement("footer", { className: classes.footer },
                    React.createElement("div", { className: classes.footerContent },
                        React.createElement(Typography_1.default, { className: classes.footerTitle }, "Stay up to date on API Documentation"),
                        subscribed ? (React.createElement("div", null,
                            React.createElement(Typography_1.default, { paragraph: true }, "Thank you for registering. We'll email you with any API documentation updates."))) : (React.createElement("div", { className: classes.subscribeForm },
                            error && (React.createElement(Typography_1.default, { paragraph: true, color: "error" }, error)),
                            subscribed && (React.createElement(Typography_1.default, { paragraph: true }, "Subscribed successfully!")),
                            React.createElement(TextField_1.default, { InputProps: {
                                    disableUnderline: true,
                                    className: classes.input
                                }, placeholder: "Your name", className: classes.textField, onChange: handleSetName }),
                            React.createElement(TextField_1.default, { placeholder: "Your email address", InputProps: {
                                    disableUnderline: true,
                                    className: classes.input
                                }, className: classes.textField, onChange: handleSetEmail }),
                            React.createElement(Button_1.default, { onClick: handleSubscribe, className: classes.button, variant: "outlined" }, "Get updates")))),
                    React.createElement("div", { className: classes.footerCopyright }, "\u00A9 SKUx Developers 2019. All rights reserved."))))));
}
exports.default = Main;
