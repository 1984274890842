"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Typography_1 = require("@material-ui/core/Typography");
const Divider_1 = require("@material-ui/core/Divider");
const Scalars_1 = require("./Scalars");
const Objects_1 = require("./Objects");
const InputObjects_1 = require("./InputObjects");
const Enums_1 = require("./Enums");
const EntitySchema_1 = require("./EntitySchema");
const useStyles = styles_1.makeStyles(theme => ({
    entityTitle: {
        fontSize: 16,
        fontWeight: "bold"
    },
    entityDescription: {
        fontSize: 14
    },
    divider: {
        marginTop: 60,
        marginBottom: 47,
        borderBottom: "2px solid #E1E1E1"
    },
    tableTitle: {
        color: "#525252",
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: "17px",
        marginTop: 30,
        marginBottom: 10
    }
}));
const GraphQLTypeReference = {
    OBJECT: "Object",
    SCALAR: "Scalar",
    ENUM: "Enum",
    INPUT_OBJECT: "InputObject"
};
const GraphQLComponentReference = {
    Object: Objects_1.default,
    Scalar: Scalars_1.default,
    Enum: Enums_1.default,
    InputObject: InputObjects_1.default
};
function _findTypeByKind(schema, typeName) {
    const kind = schema.types.find(t => t.name === typeName).kind;
    return GraphQLTypeReference[kind];
}
function APIReference(props) {
    const classes = useStyles();
    const { schema, schemaRaw, entityName } = props;
    const types = schema.getTypeMap();
    const findTypeByKind = _findTypeByKind.bind(this, schemaRaw);
    let entityType = entityName ? findTypeByKind(entityName) : props.entityType;
    let entities = [];
    if (entityName) {
        entities = [types[entityName]];
    }
    else if (entityType) {
        if (["Query", "Mutation"].includes(entityType)) {
            entities = [types[entityType]];
        }
        else {
            entities = Object.values(types).filter(t => findTypeByKind(t.name) === entityType &&
                !["Query", "Mutation"].includes(t["name"]));
        }
    }
    entities = entities.filter(e => !e.name.startsWith("__"));
    const APIComponent = GraphQLComponentReference[entityType];
    return (React.createElement("div", { className: "api-reference" },
        React.createElement(Typography_1.default, { paragraph: true, variant: "h3" },
            "API Reference",
            entityType && ": " + (entityName || entityType)),
        APIComponent ? (React.createElement(APIComponent, { findTypeByKind: findTypeByKind, entities: entities })) : (entities.map(entity => Object.values(entity.getFields()).map((q, i) => q["name"] !== "_empty" && (React.createElement("div", { key: q["name"], className: classes.block },
            React.createElement(Typography_1.default, { className: classes.entityTitle, variant: "h6" }, q["name"]),
            React.createElement(Typography_1.default, { className: classes.entityDescription, paragraph: true, variant: "subtitle1" }, q["description"]),
            q["args"] && (React.createElement("div", null,
                React.createElement(Typography_1.default, { className: classes.tableTitle, variant: "body2" }, "Arguments"),
                React.createElement(EntitySchema_1.default, { findTypeByKind: findTypeByKind, key: q["name"], entity: q["args"] }))),
            q["type"].getFields() && (React.createElement("div", null,
                React.createElement(Typography_1.default, { className: classes.tableTitle, variant: "body2" }, "Return type"),
                React.createElement(EntitySchema_1.default, { findTypeByKind: findTypeByKind, key: q["type"], entity: Object.values(q["type"].getFields()) }))),
            i + 1 !== Object.values(entity.getFields()).length && (React.createElement(Divider_1.default, { className: classes.divider })))))))));
}
exports.default = APIReference;
