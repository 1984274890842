"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const actions_1 = require("../../actions");
const Playground_1 = require("./Playground");
require("./style.css");
const { NODE_ENV, GRAPHQL_HOST, GRAPHQL_SERVICE_ENDPOINT, INTROSPECTION_TOKEN } = process.env;
class GraphQLPlaygroundView extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            settings: {
                "schema.polling.endpointFilter": `*${GRAPHQL_HOST}*`,
                "editor.theme": "light"
            }
        };
    }
    componentWillMount() {
        this.props.setEndpoint(GRAPHQL_SERVICE_ENDPOINT);
    }
    render() {
        return (React.createElement(Playground_1.default, { endpoint: GRAPHQL_SERVICE_ENDPOINT, settings: this.state.settings, config: this.props.config }));
    }
}
const mapStateToProps = state => ({
    config: state.config
});
const mapDispatchToProps = dispatch => ({
    setEndpoint: endpoint => {
        dispatch(actions_1.setEndpoint(endpoint));
    }
});
const graphQLPlayground = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(GraphQLPlaygroundView);
exports.default = graphQLPlayground;
