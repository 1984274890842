"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Grid_1 = require("@material-ui/core/Grid");
const styles_1 = require("@material-ui/core/styles");
const Typography_1 = require("@material-ui/core/Typography");
const Divider_1 = require("@material-ui/core/Divider");
const CodeBlock_1 = require("./CodeBlock");
const Title_1 = require("../Title");
const Link_1 = require("../Link");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {},
    arrow: {
        alignSelf: "center",
        color: "#525252"
    },
    screenshot: {
        width: "70%",
        height: "auto",
        margin: "40px 0",
        display: "block",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 20px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px"
    },
    divider: {
        marginTop: 60,
        marginBottom: 47,
        borderBottom: "1px solid #E1E1E1"
    }
}));
function SampleWalkthrough(props) {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(Title_1.default, null, "Sample Walkthrough"),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Prerequisites"),
        React.createElement(Typography_1.default, { paragraph: true }, "In order to make authenticated GraphQL queries, you'll need to sign up."),
        React.createElement(Typography_1.default, { paragraph: true },
            "Enter your name and email on the",
            " ",
            React.createElement(Link_1.default, { to: "/api/auth" }, " authentication page"),
            "."),
        React.createElement("a", { href: "/images/auth-screenshot.png" },
            React.createElement("img", { className: classes.screenshot, src: "/images/auth-screenshot.png" })),
        React.createElement(Typography_1.default, { paragraph: true },
            "After that, you will be automatically redirected to",
            " ",
            React.createElement(Link_1.default, { to: "/api/playground" }, "GraphQL Playground"),
            ", where you can make GraphQL queries and mutations with schema autocomplete, and browse the schema documentation."),
        React.createElement("a", { href: "/images/playground-screenshot.png" },
            React.createElement("img", { className: classes.screenshot, src: "/images/playground-screenshot.png" })),
        React.createElement(Typography_1.default, { paragraph: true }, "We will also send email with access keys and your wallet id to be able to explore GraphQL API from your apps."),
        React.createElement("a", { href: "/images/email-screenshot.png" },
            React.createElement("img", { className: classes.screenshot, src: "/images/email-screenshot.png" })),
        React.createElement(Divider_1.default, { className: classes.divider }),
        React.createElement(Title_1.default, null, "Exploring the API"),
        React.createElement(Typography_1.default, { paragraph: true },
            React.createElement(Link_1.default, { to: "/api/playground" }, "GraphQL Playground"),
            " is the ultimate place to explore our GraphQL API, based on GraphiQL sandbox. It supports schema autocomplete, and has built-in schema documentation."),
        React.createElement("a", { href: "/images/playground-query-screenshot.png" },
            React.createElement("img", { className: classes.screenshot, src: "/images/playground-query-screenshot.png" })),
        React.createElement(Typography_1.default, { paragraph: true },
            "You can also build GraphQL queries and mutations using fields and checkboxes from our GraphQL Explorer page:",
            React.createElement("ul", null,
                React.createElement("li", null, "It can be a simpler way to explore the API with no prior knowledge of GraphQL"),
                React.createElement("li", null, "It provides a whole picture of the API capabilities in a single hierarchy of queries, mutations and their respective fields"))),
        React.createElement("a", { href: "/images/explorer-screenshot.png" },
            React.createElement("img", { className: classes.screenshot, src: "/images/explorer-screenshot.png" })),
        React.createElement(Divider_1.default, { className: classes.divider }),
        React.createElement(Title_1.default, null, "Sample queries"),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Search Example"),
        React.createElement(Grid_1.default, { container: true },
            React.createElement(Grid_1.default, { item: true, xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Query"),
                React.createElement(CodeBlock_1.default, { value: `
query {
  searchOffers(filters: {
  utcDatetime: "2019-06-21 00:00:00" 
}) {
    success
    offers {
      offerId
      sku
      upc
      description
      title
      type
      value
    }
  }
}
            ` })),
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Response"),
                React.createElement(CodeBlock_1.default, { value: `
{
  "data": {
    "searchOffers": {
      "success": true,
      "offers": [
        {
          "offerId": "76988371-7322-4ef9-b4f0-53482c04f375",
          "sku": "",
          "upc": "",
          "description": "Tender Offer 3",
          "title": "Description of tender offer 3",
          "type": "PERCENTAGE",
          "value": 10
        },
        {
          "offerId": "7883e095-16da-4727-a7d3-8f3597ed722e",
          "sku": "",
          "upc": "",
          "description": "Tender Offer 4",
          "title": "Description of tender offer 4",
          "type": "TENDER",
          "value": 100
        },
        {
          "offerId": "dbef372d-cad2-40dd-a183-251250301851",
          "sku": "",
          "upc": "",
          "description": "Sample Offer 10",
          "title": "Description of sample offer",
          "type": "BOGO",
          "value": 0
        },
        ...
      ]
    }
  }
}
` }))),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Redemption Example"),
        React.createElement(Grid_1.default, { container: true },
            React.createElement(Grid_1.default, { item: true, xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Query"),
                React.createElement(CodeBlock_1.default, { value: `
mutation {
  redeemOffer(
      redemption: {
        pricePaid: 1.5,
        offerId: "76988371-7322-4ef9-b4f0-53482c04f375"
      }, 
      distributions: {
        walletId: "5bf4849a-4ed1-49e9-8619-5b852c6dcec3",
        value: 10,
        type: "PERCENTAGE"}
) {
    redemption {
      redemptionId
      pricePaid
      status
    }
  }
}
` })),
            React.createElement(Grid_1.default, { item: true, xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Response"),
                React.createElement(CodeBlock_1.default, { value: `
{
  "data": {
    "redeemOffer": {
      "redemption": {
        "redemptionId": "91eeb5e7-dc10-4666-b5a6-5345e6f3a0d3",
        "pricePaid": 1.5,
        "status": "PENDING"
      }
    }
  }
}
      ` }))),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Transaction History Example"),
        React.createElement(Grid_1.default, { container: true },
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Query"),
                React.createElement(CodeBlock_1.default, { value: `
query {
  getTransactions(
    walletId: "5bf4849a-4ed1-49e9-8619-5b852c6dcec3"
) {
    transactions {
      amountCents
      createdOn
      status
      transactionId
      type
      walletId
    }
  }
}
` })),
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Response"),
                React.createElement(CodeBlock_1.default, { value: `
{
  "data": {
    "getTransactions": {
      "transactions": [
        {
          "amountCents": 15,
          "createdOn": "2019-10-22T17:05:00.390Z",
          "status": "COMPLETED",
          "transactionId": "bc63730c-e468-4378-9a76-6e0f2b863e10",
          "type": "TRANSFER",
          "walletId": "5bf4849a-4ed1-49e9-8619-5b852c6dcec3"
        }
      ]
    }
  }
}
      ` }))),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Balance Request Example"),
        React.createElement(Grid_1.default, { container: true },
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Query"),
                React.createElement(CodeBlock_1.default, { value: `
query {
  getWallet(
    walletId: "5bf4849a-4ed1-49e9-8619-5b852c6dcec3"
) {
    wallet {
      vendorId
      balanceClearedInCents
      currency
    }
  }
}
` })),
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Response"),
                React.createElement(CodeBlock_1.default, { value: `
{
  "data": {
    "getWallet": {
      "wallet": {
        "vendorId": "1b3ca7d0-0212-4fa9-8e03-ac8eb1b743f7",
        "balanceInCents": 10000,
        "currency": "USD"
      }
    }
  }
}
` }))),
        React.createElement(Title_1.default, { variant: "subtitle" }, "Payments History Request Example"),
        React.createElement(Grid_1.default, { container: true },
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Query"),
                React.createElement(CodeBlock_1.default, { value: `
query {
  getRedemption(
    redemptionId: "91eeb5e7-dc10-4666-b5a6-5345e6f3a0d3"
) {
    redemption {
      distributions {
        type
        requestId
        value
        walletId
      }
      offerId
      pricePaid
    }
  }
}
` })),
            React.createElement(Grid_1.default, { xs: 12, md: 6 },
                React.createElement(Typography_1.default, { variant: "body2" }, "Response"),
                React.createElement(CodeBlock_1.default, { value: `
{
  "data": {
    "getRedemption": {
      "redemption": {
        "distributions": [
          {
            "type": "PERCENTAGE",
            "requestId": "e9f19418-23a1-4d84-b831-ab05f58cccaf",
            "value": 3,
            "walletId": "56fae0dc-1bd5-4f74-a18d-0eca679573ca"
          },
          {
            "type": "PERCENTAGE",
            "requestId": "87e5402c-5029-4f31-afd3-dc74dfb8076d",
            "value": 10,
            "walletId": "5bf4849a-4ed1-49e9-8619-5b852c6dcec3"
          }
        ],
        "offerId": "76988371-7322-4ef9-b4f0-53482c04f375",
        "pricePaid": 1.5
      }
    }
  }
}
` })))));
}
exports.default = SampleWalkthrough;
