"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactDOM = require("react-dom");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const graphql_1 = require("graphql");
const Main_1 = require("./components/Main");
const reducers_1 = require("./reducers");
const store = redux_1.createStore(reducers_1.playground);
const { NODE_ENV, GRAPHQL_SERVICE_ENDPOINT, INTROSPECTION_TOKEN } = process.env;
function fetcher(params) {
    return fetch(GRAPHQL_SERVICE_ENDPOINT, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Skux-Access-Token": INTROSPECTION_TOKEN
        },
        body: JSON.stringify(params)
    })
        .then(function (response) {
        return response.text();
    })
        .then(function (responseBody) {
        try {
            return JSON.parse(responseBody);
        }
        catch (e) {
            return responseBody;
        }
    });
}
function init() {
    fetcher({
        query: graphql_1.getIntrospectionQuery()
    }).then(result => {
        ReactDOM.render(React.createElement(react_redux_1.Provider, { store: store },
            React.createElement(Main_1.default, { schemaRaw: result.data.__schema, schema: graphql_1.buildClientSchema(result.data) })), document.getElementById("root"));
    });
}
init();
