"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const router_1 = require("@reach/router");
const Link_1 = require("@material-ui/core/Link");
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {},
}));
function default_1(props) {
    const classes = useStyles();
    return React.createElement(Link_1.default, Object.assign({ className: classes.root, component: router_1.Link }, props));
}
exports.default = default_1;
