"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const react_redux_1 = require("react-redux");
const graphql_playground_react_1 = require("graphql-playground-react");
const Title_1 = require("../Title");
const useStyles = styles_1.makeStyles(theme => ({
    playground: {
        minWidth: 750
    }
}));
function GraphQLPlayground(props) {
    const classes = useStyles();
    const { endpoint, settings, config } = props;
    return (React.createElement("div", { className: "graphql-playground" },
        React.createElement(Title_1.default, null, "GraphQL Playground"),
        React.createElement(react_redux_1.Provider, { store: graphql_playground_react_1.store },
            React.createElement(graphql_playground_react_1.Playground, { endpoint: endpoint, settings: settings, config: config }))));
}
exports.default = GraphQLPlayground;
