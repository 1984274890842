"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const CodeMirror = require("codemirror");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/comment/comment");
require("codemirror/addon/edit/matchbrackets");
require("codemirror/addon/edit/closebrackets");
require("codemirror/addon/fold/foldgutter");
require("codemirror/addon/fold/brace-fold");
require("codemirror/addon/search/search");
require("codemirror/addon/search/searchcursor");
require("codemirror/addon/search/jump-to-line");
require("codemirror/addon/dialog/dialog");
require("codemirror/addon/lint/lint");
require("codemirror/keymap/sublime");
require("graphiql/node_modules/codemirror-graphql/hint");
require("graphiql/node_modules/codemirror-graphql/lint");
require("graphiql/node_modules/codemirror-graphql/info");
require("graphiql/node_modules/codemirror-graphql/jump");
require("graphiql/node_modules/codemirror-graphql/mode");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {
        padding: theme.spacing(2),
        paddingLeft: 0
    },
    "@global": {
        ".CodeMirror": {
            backgroundColor: "#f6f7f7",
            padding: theme.spacing(1),
            border: "1px solid #eee",
            height: "auto",
        }
    }
}));
function CodeBlock({ value, options = {} }) {
    const classes = useStyles();
    let node = React.createRef();
    let editor = {};
    React.useEffect(() => {
        editor.codemirror = new CodeMirror(node.current, Object.assign({ value, mode: "graphql", tabSize: 2, readOnly: true }, options));
        return function () {
            delete editor.codemirror;
        };
    }, []);
    return React.createElement("div", { className: classes.root, ref: node });
}
exports.default = CodeBlock;
