"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const router_1 = require("@reach/router");
const Typography_1 = require("@material-ui/core/Typography");
const Grid_1 = require("@material-ui/core/Grid");
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    "@global": {
        "#container": {
            backgroundImage: "url(/images/home-bg.png)",
            backgroundSize: "100%",
            width: "100%"
        },
        "#root": {
            overflow: "hidden"
        },
        footer: {
            paddingTop: 53,
            height: "304px !important"
        }
    },
    root: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: 1050
        }
    },
    main: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse"
        }
    },
    hero: {
        [theme.breakpoints.down("sm")]: {
            width: "200%"
        },
        [theme.breakpoints.up("sm")]: {
            height: 474,
            width: 641,
            marginTop: 57
        }
    },
    title: {
        [theme.breakpoints.down("sm")]: {
            padding: "0 12px"
        },
        [theme.breakpoints.up("sm")]: {
            margin: "0 auto",
            width: 520
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 170,
            margin: "0 auto",
            width: 386
        }
    },
    mainTitleText: {
        fontWeight: "bold",
        lineHeight: "50px",
        fontSize: 40,
        color: "#262626"
    },
    secondaryTitleText: {
        fontSize: 20
    },
    icon: {
        height: 77
    },
    points: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            margin: "10px auto"
        },
        display: "flex",
        margin: "0 auto",
        position: "relative",
        top: 52
    },
    casePoint: {
        [theme.breakpoints.down("sm")]: {
            width: "90%"
        },
        [theme.breakpoints.up("md")]: {
            "&:first-child": {
                marginLeft: 0
            }
        },
        margin: 15,
        padding: 15,
        height: 180,
        width: 330,
        borderRadius: 5,
        backgroundColor: "#262626",
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.05)"
    },
    casePointContent: {
        marginTop: 19
    },
    casePointTitle: {
        color: "#FFFFFF",
        fontSize: 16,
        marginBottom: 9,
        fontWeight: "bold",
        lineHeight: "19px"
    },
    casePointText: {
        color: "#C0C0C0",
        fontSize: 16,
        lineHeight: "24px"
    },
    link: {
        textDecoration: "none",
        fontSize: 16,
        color: "#FFAA29",
        fontWeight: "bold"
    }
}));
function Home(props) {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(Grid_1.default, { container: true, className: classes.main },
            React.createElement(Grid_1.default, { item: true, md: 6, sm: 12, xs: 12 },
                React.createElement("div", { className: classes.title },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.mainTitleText }, "Digitally Transform Offer Redemption and Payment"),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.secondaryTitleText }, "SKUx enables retailers to track redemptions and get paid faster"))),
            React.createElement(Grid_1.default, { item: true, xs: 6 },
                React.createElement("img", { className: classes.hero, alt: "hero image", src: "/images/hero-img.png" }))),
        React.createElement(Grid_1.default, { container: true },
            React.createElement("div", { className: classes.points },
                React.createElement("div", { className: classes.casePoint },
                    React.createElement(Grid_1.default, { container: true, className: classes.casePointContent },
                        React.createElement(Grid_1.default, { item: true, xs: 4 },
                            React.createElement("img", { className: classes.icon, alt: "how it works", src: "/images/icon-how it works.png" })),
                        React.createElement(Grid_1.default, { item: true, xs: 8 },
                            React.createElement(Typography_1.default, { paragraph: true, variant: "h6", className: classes.casePointTitle }, "The SKUx Story"),
                            React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.casePointText }, "Disrupting a Decades-Old Practice"),
                            React.createElement(router_1.Link, { className: classes.link, to: "/story" }, "Learn More >")))),
                React.createElement("div", { className: classes.casePoint },
                    React.createElement(Grid_1.default, { container: true, className: classes.casePointContent },
                        React.createElement(Grid_1.default, { item: true, xs: 4 },
                            React.createElement("img", { className: classes.icon, alt: "graphql", src: "/images/icon-graphql.png" })),
                        React.createElement(Grid_1.default, { item: true, xs: 8 },
                            React.createElement(Typography_1.default, { paragraph: true, variant: "h6", className: classes.casePointTitle }, "API Testing"),
                            React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.casePointText }, "Try the API on our GraphQL Playground"),
                            React.createElement(router_1.Link, { className: classes.link, to: "/api/playground" }, "Start Playing  >")))),
                React.createElement("div", { className: classes.casePoint },
                    React.createElement(Grid_1.default, { container: true, className: classes.casePointContent },
                        React.createElement(Grid_1.default, { item: true, xs: 4 },
                            React.createElement("img", { className: classes.icon, alt: "api", src: "/images/icon-api.png" })),
                        React.createElement(Grid_1.default, { item: true, xs: 8 },
                            React.createElement(Typography_1.default, { paragraph: true, variant: "h6", className: classes.casePointTitle }, "API Documentation"),
                            React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.casePointText }, "Read our API Documentation Guides"),
                            React.createElement(router_1.Link, { className: classes.link, to: "/api/reference/Query" },
                                "Explore Docs ",
                                " >"))))))));
}
exports.default = Home;
