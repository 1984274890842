"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const router_1 = require("@reach/router");
const Table_1 = require("@material-ui/core/Table");
const TableBody_1 = require("@material-ui/core/TableBody");
const TableCell_1 = require("@material-ui/core/TableCell");
const TableRow_1 = require("@material-ui/core/TableRow");
function EntitySchema({ entity, findTypeByKind }) {
    return (React.createElement(Table_1.default, null,
        React.createElement("colgroup", null,
            React.createElement("col", { style: { width: "50%" } }),
            React.createElement("col", { style: { width: "50%" } })),
        React.createElement(TableBody_1.default, null, entity.map(prop => {
            const entityType = prop.type.ofType || prop.type;
            return (React.createElement(TableRow_1.default, { key: prop.name },
                React.createElement(TableCell_1.default, null,
                    prop.name,
                    " (",
                    React.createElement(router_1.Link, { to: `/api/reference/${findTypeByKind(entityType.name)}/${entityType.name}` }, prop.type.toString()),
                    ")"),
                React.createElement(TableCell_1.default, null, prop.description)));
        }))));
}
exports.default = EntitySchema;
