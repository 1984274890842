"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const router_1 = require("@reach/router");
const styles_1 = require("@material-ui/core/styles");
const Grid_1 = require("@material-ui/core/Grid");
const Typography_1 = require("@material-ui/core/Typography");
const Button_1 = require("@material-ui/core/Button");
const LinearProgress_1 = require("@material-ui/core/LinearProgress");
const Title_1 = require("../Title");
const actions_1 = require("../../actions");
const { GET_TOKEN_ENDPOINT, REST_SERVICE_ENDPOINT } = process.env;
const jsonRequest = (url, data) => __awaiter(this, void 0, void 0, function* () {
    return yield fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    }).then(resp => {
        if (!resp.ok) {
            throw resp;
        }
        return resp.json();
    });
});
const verify = email => jsonRequest(`${REST_SERVICE_ENDPOINT}/verify`, { email });
const signup = (name, email) => jsonRequest(`${REST_SERVICE_ENDPOINT}/signup`, { name, email });
const useStyles = styles_1.makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    textField: {
        height: 40,
        width: 240,
        border: "1px solid #DDDDDD",
        backgroundColor: "#FFFFFF",
        marginRight: 14,
        paddingLeft: 15,
        fontSize: 16,
        lineHeight: 24
    },
    button: {
        backgroundColor: "#FFAA29",
        borderRadius: 5,
        color: "#FFFFFF",
        fontWeight: "bold",
        "&:hover": {
            boxShadow: "none",
            backgroundColor: "#EF9205",
            color: "#FFFFFF"
        }
    }
}));
const Credentials = props => {
    const classes = useStyles();
    const [name, setName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const handleSetName = e => setName(e.target.value);
    const handleSetEmail = e => setEmail(e.target.value);
    const handleSignup = () => {
        if (name && email) {
            setLoading(true);
            signup(name, email)
                .then(response => {
                response.credentials && props.onSuccess(response.credentials);
            })
                .catch(e => setError("There was an error: ", e));
        }
        else {
            setError("Please provide name and email");
        }
    };
    return (React.createElement(Grid_1.default, { item: true, xs: 12 },
        React.createElement("div", { className: classes.root },
            error && (React.createElement(Typography_1.default, { paragraph: true, color: "error" }, error)),
            loading && (React.createElement(Typography_1.default, { paragraph: true },
                React.createElement(LinearProgress_1.default, null))),
            React.createElement("input", { placeholder: "Your name", className: classes.textField, onChange: handleSetName }),
            React.createElement("input", { placeholder: "Your email address", className: classes.textField, onChange: handleSetEmail })),
        React.createElement(Button_1.default, { variant: "contained", color: "inherit", className: classes.button, onClick: handleSignup, disabled: props.authenticated }, "Get Token")));
};
class GetTokenView extends React.Component {
    constructor() {
        super(...arguments);
        this.handleCredentials = (credentials) => __awaiter(this, void 0, void 0, function* () {
            yield fetch(GET_TOKEN_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(credentials)
            })
                .then(resp => resp.json())
                .then(resp => this.props.setToken(resp.token))
                .then(() => router_1.navigate("/api/playground"));
        });
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Title_1.default, null, "Authentication"),
            React.createElement(Grid_1.default, { container: true, justify: "center" },
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2" }, "In order to make authenticated GraphQL queries, you'll need an authentication token."),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h6" }, "How to use a token"),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2" },
                        "Make a POST to ",
                        GET_TOKEN_ENDPOINT,
                        "."),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2" }, "Enter your credentials and try."),
                    React.createElement(Credentials, { authenticated: this.props.authenticated, onSuccess: this.handleCredentials })))));
    }
}
const mapStateToProps = state => ({
    authenticated: state.authenticated
});
const mapDispatchToProps = dispatch => ({
    setToken: token => {
        dispatch(actions_1.changeToken(token));
    }
});
const getToken = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(GetTokenView);
exports.default = getToken;
