"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Typography_1 = require("@material-ui/core/Typography");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {
        fontFamily: "GT America",
        fontSize: 36,
        fontWeight: "bold",
        lineHeight: "43px",
        marginTop: 16,
        marginBottom: 30
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 400,
        marginBottom: 16
    }
}));
function Title({ variant, children }) {
    const classes = useStyles();
    return (React.createElement(Typography_1.default, { className: `${classes.root} ${variant === "subtitle" &&
            classes.subtitle}` }, children));
}
exports.default = Title;
