"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Typography_1 = require("@material-ui/core/Typography");
const Grid_1 = require("@material-ui/core/Grid");
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => styles_1.createStyles({
    root: {
        maxWidth: 1410,
        margin: "0 auto",
        marginBottom: 100
    },
    hero: {
        maxWidth: "1410px"
    },
    heroImg: {
        width: "100%",
        marginTop: -150,
        zIndex: -1
    },
    main: {
        marginTop: -400,
        backgroundColor: "#fff"
    },
    textAreaContainer: {
        backgroundColor: "#fff",
        maxWidth: 1050,
        margin: "0 auto"
    },
    textArea: {
        backgroundColor: "#fff",
        paddingTop: 100,
        margin: "0 auto",
        marginTop: -100,
        marginBottom: 100,
        width: 690,
        color: "#525252"
    },
    title: {
        fontWeight: "bold",
        lineHeight: "50px",
        fontSize: 40,
        marginBottom: theme.spacing(8)
    },
    mainText: {
        fontSize: 20,
        lineHeight: "30px"
    },
    halfImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        height: 367,
        width: "auto",
        display: "block",
        margin: "auto"
    },
    left: {
        paddingLeft: 180,
        paddingRight: 105,
        display: "flex",
        backgroundColor: "#F8F8F8"
    },
    right: {
        paddingLeft: 105,
        paddingRight: 180,
        display: "flex",
        backgroundColor: "#F8F8F8"
    },
    centered: {
        margin: "auto auto"
    },
    examples: {
        maxWidth: 1050,
        margin: "0 auto",
        marginTop: 60
    },
    examplesTitle: {
        marginTop: 40,
        fontSize: 24,
        lineHeight: "29px",
        fontWeight: "bold"
    }
}));
function Story(props) {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.hero },
            React.createElement("img", { className: classes.heroImg, alt: "hero", src: "/images/background.jpg" })),
        React.createElement(Grid_1.default, { container: true },
            React.createElement(Grid_1.default, { item: true, xs: 12, className: classes.main },
                React.createElement("div", { className: classes.textAreaContainer },
                    React.createElement("div", { className: classes.textArea },
                        React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.title }, "The SKUx Story"),
                        React.createElement(Typography_1.default, { paragraph: true, variant: "body2" },
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "For decades paper offers distributed in weekly newspapers formed the cornerstone of CPG promotional activities. However, printing millions of offers presented tremendous risk to marketers trying to keep their promotions within a budget."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "In the meantime, the redemption process for retailers developed into a cottage industry of its own, involving shoeboxes of offers shipped down to Mexico, where they were sorted, weighed and presented for settlement."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "Both sides of the equation were rife with risk, fraud, and delays. Until now."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "SKUx is designed to unlock lost value across the trillion-dollar promotional offer and offer industry. Using blockchain technology, SKUx is introducing a digital transformation to offers and offers designed to reduce fraud and improve settlement times."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "SKUx offers a win-win proposition to CPG brands and retailers. Our platform reduces risk of going overbudget to 0%, while processing timely payments to retailers to cover the cost of the offer."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "Both retailers and brands have full transparency into their promotional activities, through the immutable proof of transactions recorded on the public blockchain."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "SKUx is a Fintech company utilizing smart, secure, one-time use digital incentives to drive incremental spend to retailers."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "3 WAYS WE HELP DRIVE NEW BUSINESS"),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "1. CUTTING EDGE PAYMENT SOLUTIONS We deliver secure, one-time use digital cash codes to drive sales in a seamless and secure fashion, eliminating fraud."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "2. NEW INCREMENTAL REVENUE In an on-demand economy, retailers are desiring incremental revenue sources and brands are demanding ROI."),
                            React.createElement(Typography_1.default, { paragraph: true, className: classes.mainText }, "3. COMPREHENSIVE REPORTING Our patent-pending analytical tool set provides dashboard insights and ensures transparency for all stakeholders, with provable ROI for partners.")))),
                React.createElement(Typography_1.default, { paragraph: true, align: "center", variant: "h4", className: classes.title }, "3 ways we help drive new business")),
            React.createElement(Grid_1.default, { item: true, xs: 6, className: classes.left },
                React.createElement("div", { className: classes.centered },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.examplesTitle }, "CUTTING EDGE PAYMENT SOLUTIONS"),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.mainText }, "We deliver secure, one-time use digital cash codes to drive sales in a seamless and secure fashion, eliminating fraud."))),
            React.createElement(Grid_1.default, { item: true, xs: 6 },
                React.createElement("img", { className: classes.halfImg, alt: "mobile", src: "/images/mobile-img3.png" })),
            React.createElement(Grid_1.default, { item: true, xs: 6 },
                React.createElement("img", { className: classes.halfImg, alt: "mobile", src: "/images/mobile-img1.png" })),
            React.createElement(Grid_1.default, { item: true, xs: 6, className: classes.right },
                React.createElement("div", { className: classes.centered },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.examplesTitle }, "NEW INCREMENTAL REVENUE"),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.mainText }, "In an on-demand economy, retailers are desiring incremental revenue sources and brands are demanding ROI."))),
            React.createElement(Grid_1.default, { item: true, xs: 6, className: classes.left },
                React.createElement("div", { className: classes.centered },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.examplesTitle }, "COMPREHENSIVE REPORTING"),
                    React.createElement(Typography_1.default, { paragraph: true, variant: "body2", className: classes.mainText }, "Our patent-pending analytical tool set provides dashboard insights and ensures transparency for all stakeholders, with provable ROI for partners. Both retailers and brands have full transparency into their promotional activities, through the immutable proof of transactions recorded on the public blockchain."))),
            React.createElement(Grid_1.default, { item: true, xs: 6 },
                React.createElement("img", { className: classes.halfImg, alt: "mobile", src: "/images/mobile-img2.png" })),
            React.createElement("div", { className: classes.examples },
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.examplesTitle }, "Redeem Offer"),
                    React.createElement("img", { alt: "redeem-offer", src: "/images/redeem-offer-img.png" })),
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.examplesTitle }, "Query Offer"),
                    React.createElement("img", { alt: "redeem-offer", src: "/images/query-offer-img.png" })),
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement(Typography_1.default, { paragraph: true, variant: "h4", className: classes.examplesTitle }, "Payment Mechanism"),
                    React.createElement("img", { alt: "redeem-offer", src: "/images/payment-mechanism-img.png" }))))));
}
exports.default = Story;
