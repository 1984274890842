"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const merge = require("deepmerge");
const { INTROSPECTION_TOKEN, } = process.env;
const initialState = {
    authenticated: false,
    config: {
        extensions: {
            endpoints: {
                dev: {
                    url: '',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Skux-Access-Token': INTROSPECTION_TOKEN
                    },
                },
            },
        },
    },
};
exports.playground = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_TOKEN':
            return merge(state, {
                authenticated: true,
                config: {
                    extensions: {
                        endpoints: {
                            dev: {
                                headers: {
                                    'X-Skux-Access-Token': action.token,
                                },
                            },
                        },
                    },
                },
            });
        case 'SET_ENDPOINT':
            return merge(state, {
                config: {
                    extensions: {
                        endpoints: {
                            dev: {
                                url: action.endpoint,
                            },
                        },
                    },
                },
            });
        default:
            return state;
    }
};
