"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles_1 = require("@material-ui/core/styles");
const Typography_1 = require("@material-ui/core/Typography");
const Divider_1 = require("@material-ui/core/Divider");
const EntitySchema_1 = require("./EntitySchema");
const useStyles = styles_1.makeStyles(theme => ({
    entityTitle: {
        fontSize: 16,
        fontWeight: "bold"
    },
    entityDescription: {
        fontSize: 14
    },
    divider: {
        marginTop: 60,
        marginBottom: 47,
        borderBottom: "2px solid #E1E1E1"
    },
    tableTitle: {
        color: "#525252",
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: "17px",
        marginTop: 30,
        marginBottom: 10
    }
}));
function Objects({ entities, findTypeByKind }) {
    const classes = useStyles();
    return entities.map((entity, i) => (React.createElement("div", { key: i },
        React.createElement(Typography_1.default, { className: classes.entityTitle, variant: "h6" }, entity["name"]),
        React.createElement(Typography_1.default, { className: classes.entityDescription, paragraph: true, variant: "subtitle1" }, entity["description"]),
        React.createElement(EntitySchema_1.default, { findTypeByKind: findTypeByKind, entity: Object.values(entity.getFields()) }),
        i + 1 !== entities.length && React.createElement(Divider_1.default, { className: classes.divider }))));
}
exports.default = Objects;
