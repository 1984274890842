"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const GraphiQL = require("graphiql");
const graphiql_explorer_1 = require("graphiql-explorer");
const graphql_1 = require("graphql");
const Title_1 = require("../Title");
require("graphiql/graphiql.css");
require("./style.css");
const { NODE_ENV, GRAPHQL_SERVICE_ENDPOINT, INTROSPECTION_TOKEN } = process.env;
function fetcher(params) {
    return fetch(GRAPHQL_SERVICE_ENDPOINT, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Skux-Access-Token": INTROSPECTION_TOKEN
        },
        body: JSON.stringify(params)
    })
        .then(function (response) {
        return response.text();
    })
        .then(function (responseBody) {
        try {
            return JSON.parse(responseBody);
        }
        catch (e) {
            return responseBody;
        }
    });
}
const DEFAULT_QUERY = `# shift-option/alt-click on a query below to jump to it in the explorer
# option/alt-click on a field in the explorer to select all subfields
query {
    searchOffers {
      requestId
      success
      error {
        name
        code
        displayMessage
      }
      offers {
        offerId
        sku
        upc
        description
        title
        type
        value
      }
    }
  }
`;
class GraphQLExplorer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { schema: null, query: DEFAULT_QUERY, explorerIsOpen: true };
        this._handleInspectOperation = (cm, mousePos) => {
            const parsedQuery = graphql_1.parse(this.state.query || "");
            if (!parsedQuery) {
                console.error("Couldn't parse query document");
                return null;
            }
            var token = cm.getTokenAt(mousePos);
            var start = { line: mousePos.line, ch: token.start };
            var end = { line: mousePos.line, ch: token.end };
            var relevantMousePos = {
                start: cm.indexFromPos(start),
                end: cm.indexFromPos(end)
            };
            var position = relevantMousePos;
            var def = parsedQuery.definitions.find(definition => {
                if (!definition.loc) {
                    console.log("Missing location information for definition");
                    return false;
                }
                const { start, end } = definition.loc;
                return start <= position.start && end >= position.end;
            });
            if (!def) {
                console.error("Unable to find definition corresponding to mouse position");
                return null;
            }
            var operationKind = def.kind === "OperationDefinition"
                ? def.operation
                : def.kind === "FragmentDefinition"
                    ? "fragment"
                    : "unknown";
            var operationName = def.kind === "OperationDefinition" && !!def.name
                ? def.name.value
                : def.kind === "FragmentDefinition" && !!def.name
                    ? def.name.value
                    : "unknown";
            var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;
            var el = document.querySelector(selector);
            el && el.scrollIntoView();
        };
        this._handleEditQuery = (query) => this.setState({ query });
        this._handleToggleExplorer = () => {
            this.setState({ explorerIsOpen: !this.state.explorerIsOpen });
        };
    }
    componentDidMount() {
        fetcher({
            query: graphql_1.getIntrospectionQuery()
        }).then(result => {
            const editor = this._graphiql.getQueryEditor();
            editor.setOption("extraKeys", Object.assign({}, (editor.options.extraKeys || {}), { "Shift-Alt-LeftClick": this._handleInspectOperation }));
            this.setState({ schema: graphql_1.buildClientSchema(result.data) });
        });
    }
    render() {
        const { query, schema } = this.state;
        return (React.createElement("div", null,
            React.createElement(Title_1.default, null, "GraphQL Explorer"),
            React.createElement("div", { className: "graphiql-container" },
                React.createElement(graphiql_explorer_1.default, { schema: schema, query: query, onEdit: this._handleEditQuery, onRunOperation: operationName => this._graphiql.handleRunQuery(operationName), explorerIsOpen: this.state.explorerIsOpen, onToggleExplorer: this._handleToggleExplorer }),
                React.createElement(GraphiQL, { ref: ref => (this._graphiql = ref), fetcher: fetcher, schema: schema, query: query, readOnly: true },
                    React.createElement(GraphiQL.Toolbar, null,
                        React.createElement(GraphiQL.Button, { onClick: () => this._graphiql.handlePrettifyQuery(), label: "Prettify", title: "Prettify Query (Shift-Ctrl-P)" }),
                        React.createElement(GraphiQL.Button, { onClick: () => this._graphiql.handleToggleHistory(), label: "History", title: "Show History" }),
                        React.createElement(GraphiQL.Button, { onClick: this._handleToggleExplorer, label: "Explorer", title: "Toggle Explorer" }))))));
    }
}
exports.default = GraphQLExplorer;
